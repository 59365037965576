import { getUserSpecs } from './getUserSpecs';

const isProd = !/local|staging/.test(__PAGE_VARS__.environment);

const sentryInit = ({ sentryInstance, integrations }) => {
  if (!DEV && __PAGE_VARS__.DSN) {
    sentryInstance.init({
      dsn: __PAGE_VARS__.DSN,
      integrations,
      environment: __PAGE_VARS__.environment,
      debug: false,
      release: APP_VERSION,
      tracesSampleRate: 1,
    });
    const {
      csrfTokenName,
      csrfTokenValue,
      DSN,
      logoutUrl,
      mapBoxToken,
      themes,
      userName,
      userPhoto,
      ...mainContext
    } = __PAGE_VARS__;
    sentryInstance.setContext('Main', {
      ...mainContext,
      isProd,
      specs: getUserSpecs(),
    });
    sentryInstance.setTag('DEV', DEV);
    sentryInstance.setTag('Version', APP_VERSION);
    sentryInstance.setTag('Back-end version', __PAGE_VARS__.BEV);
    sentryInstance.setTag('Build date', BUILD_DATE);
  }
};
export default sentryInit;
