// https://nystudio107.com/blog/speeding-up-tailwind-css-builds
import 'css/base.css';
import 'css/components.css';
import 'css/utilities.css';
import 'css/main.css';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import sentryInit from 'common/sentryInit';

const isProd = !/local|staging/.test(__PAGE_VARS__.environment);
// TODO : Temp workaround for runtime injected multiple times on prod build
// See twigpack getModuleTagsByPath
const singleInstance = !('__ECHO__' in window);

sentryInit({
  sentryInstance: Sentry,
  integrations: [new Integrations.BrowserTracing()],
});

if (singleInstance) {
  window.__ECHO__ = {};

  // TODO: convert logo to ascii like nyt and add message with email
  console.info(`      __       __
    _/_/ _____/ /_  ____
   / _ \\/ ___/ __ \\/ __ \\
  /  __/ /__/ / / / /_/ /
  \\___/\\___/_/ /_/\\____/`);
  console.info(
    `%c${APP_VERSION} %c${__PAGE_VARS__.BEV}`,
    'background:#d0d0d0;color:black;border-radius:4px;padding:2px 4px;border:1px solid #1d1a3f;font-size:12px',
    'background:#d0d0d0;color:black;border-radius:4px;padding:2px 4px;border:1px solid #1d1a3f;font-size:12px',
  );
  console.info(
    "Curious about what's going on under the hood? Send me a message at: atob('ZWNob0BmcmFxZS5uZXQ=') 📨",
  );
  console.info('🍁 Fait au Québec 🍁');

  // Add fade out before unload
  window.addEventListener('beforeunload', event => {
    document.body.classList.add('unloading');
  });

  window.scrollTo(0, 0);

  // Redirect now if a auto redirect link is found
  const autoRedirectLink = document.querySelector(
    '.autoRedirectLink',
  ) as HTMLAnchorElement;
  // IMPROVMENT: check validity of autoRedirectLink href value
  if (autoRedirectLink && autoRedirectLink.href !== '') {
    setTimeout(() => window.location.replace(autoRedirectLink.href), 5000);
  }

  // App main
  const main = async () => {
    const LazySizes = await import(
      /* webpackChunkName: "lazysizes" */ 'lazysizes'
    );
    await import(
      // https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/attrchange
      /* webpackChunkName: "ls.attrchange" */ 'lazysizes/plugins/attrchange/ls.attrchange'
    );
    LazySizes.init();

    const loginForm = document.getElementById('loginForm');
    if (loginForm && !loginForm.classList.contains('ready')) {
      import(
        /* webpackChunkName: "submitUserForm" */ './users/submitUserForm'
      ).then(({ submitUserForm }) => {
        const callBackOnSuccess = () => {
          const loginModal = document.getElementById('loginForm-modal');
          if (loginModal) {
            loginModal.classList.add('hidden');
            document.body.classList.remove('overflow-hidden');
          }
        };

        loginForm.onsubmit = event => {
          event.preventDefault();
          submitUserForm({ form: event.target, callBackOnSuccess });
        };
      });
    }

    return 1;
  };

  main().then(() => {
    const storiesGridList: Array<HTMLElement> = Array.from(
      document.querySelectorAll('.stories-list'),
    );
    if (storiesGridList.length) {
      import(
        /* webpackChunkName: "StoriesList" */ './stories/StoriesList'
      ).then(({ StoriesList }) => {
        storiesGridList.map(StoriesList);
      });
    }

    const storyMapPlayerElList: Array<HTMLElement> = Array.from(
      document.querySelectorAll('.story-player-standalone'),
    );
    if (storyMapPlayerElList.length) {
      import(
        /* webpackChunkName: "StandaloneStoryPlayer" */ './stories/StandaloneStoryPlayer'
      ).then(({ StandaloneStoryPlayer }) => {
        storyMapPlayerElList.map(el => StandaloneStoryPlayer({ el }));
      });
    }

    const searchStoryFormEl = document.getElementById('story-search-form');
    if (searchStoryFormEl) {
      import(/* webpackChunkName: "StorySearch" */ './stories/Search').then(
        ({ StorySearch }) => {
          StorySearch(searchStoryFormEl);
        },
      );
    }

    const collapsibleFilterList: Array<HTMLElement> = Array.from(
      document.querySelectorAll('button[data-collapsible-category]'),
    );
    if (collapsibleFilterList.length > 1) {
      const { filterScope } = collapsibleFilterList[0].dataset;
      const collapsibleContentList = Array.from(
        document.querySelectorAll(`.collapsible-scope--${filterScope}`),
      );
      collapsibleFilterList.forEach(btnEl => {
        btnEl.addEventListener('click', event => {
          const {
            collapsibleCategory,
          } = (event.target as HTMLButtonElement).dataset;

          collapsibleContentList.forEach(el => {
            if (collapsibleCategory === 'all') el.classList.remove('hidden');
            else {
              if (
                el.classList.contains(
                  `collapsible-category--${collapsibleCategory}`,
                )
              )
                el.classList.remove('hidden');
              else el.classList.add('hidden');
            }
          });
        });
      });
    }

    // Trick to hide svg element that fill the whole page before the critical was rendered
    const menuIcon = document.getElementById('nav-burger-menu-icon');
    if (menuIcon) menuIcon.style.cssText = '';

    const globalLoader = document.getElementById('global-loader-logo');
    if (globalLoader) {
      globalLoader.classList.add(
        'transition',
        'opacity-0',
        'duration-300',
        'delay-100',
      );
      setTimeout(() => {
        document.body.classList.remove('loading');
      }, 400);
    } else {
      document.body.classList.remove('loading');
    }
  });
}

if (!isProd) {
  const versionLabelEl = document.getElementById('env-versions');
  const versionLabel = `${APP_VERSION} - ${__PAGE_VARS__.BEV}`;

  if (versionLabelEl) versionLabelEl.innerHTML = versionLabel;
  else
    document.body.insertAdjacentHTML(
      'beforeend',
      `<div id="env-versions" class="fixed bg-light-gray opacity-50 z-max top-0 right-0 m-1 px-1 rounded-10 text-xs border hover:opacity-100 hover:shadow">${versionLabel}</div>`,
    );
}
// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
