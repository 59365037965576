export const getUserSpecs = () => {
  let userSpecs = [document.body.className];

  if (navigator) {
    const { userAgent, oscpu, userAgentData } = navigator;
    const platform = userAgentData?.platform || navigator.platform || 'unknown';
    userSpecs = [userAgent, oscpu || platform, ...userSpecs];
  }

  if ('screen' in window) {
    const { height, width, pixelDepth, orientation } = window.screen;
    userSpecs = [
      ...userSpecs,
      `${height}x${width}@${pixelDepth}`,
      orientation?.type,
    ];
  }

  return userSpecs;
};
